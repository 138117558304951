import React from 'react';
import { useRouter } from 'next/router';

function HomePage() {
  const router = useRouter();
  React.useEffect(() => {
    router?.push('/app/dashboard');
  });
  return <div />;
}

export default HomePage;
